import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from "pure-react-carousel";
import { message, Icon as AntdIcon } from "antd";

import { Icon } from "../Media";

import "./WildImageCarousel.scss";

export default function WideImageCarousel({ items, isClipboardEnable, url, currentSlide }) {
	if (!Array.isArray(items) || Array.isArray(items) && !items.length) {
		return null;
	}

	function copyToBuffer(text) {
		const elem = document.createElement("textarea");
		elem.value = text;
		document.body.appendChild(elem);
		elem.select();
		document.execCommand("copy");
		document.body.removeChild(elem);

		message.success("Ссылка на изображение скопирована в буфер");
	}

	let naturalSlideHeight = 14.5;

	return (
		<CarouselProvider
			naturalSlideWidth={100}
			naturalSlideHeight={naturalSlideHeight}
			currentSlide={currentSlide}
			totalSlides={items.length}
			visibleSlides={1}
			dragEnabled={items.length > 1}
			touchEnabled={items.length > 1}
			infinite={true}
			isPlaying={false}
			className={"wide-image-carousel position-relative"}
		>
			<Slider>
				{
					items.map((item, idx) => {
						const imgId = item?.id;
						const src = get(item, "src", "");

						return (
							<Slide
								key={imgId}
								index={idx}
							>
								<span
									id={imgId}
									style={{
										position: "absolute",
										top: -100,
									}}
								/>
								<Image
									className={"target-banner__image"}
									src={src}
									style={{ width: "100%" }}
								/>
								{
									isClipboardEnable && (
										<div
											style={{
												position: "absolute",
												top: "3%",
												right: "3%",
											}}
										>
											<div
												className={"copyable"}
												title={"Скопировать ссылку на это изображение"}
												onClick={() => copyToBuffer(`${url}#${imgId}?${idx}`)}
											>
												<AntdIcon type={"copy"} />
											</div>
										</div>
									)
								}
							</Slide>
						);
					})
				}
			</Slider>

			{
				items.length > 1 && (
					<div
						className={"tns-controls"}
						style={
							{
								transform: "unset",
								top: "calc(50% - 37.5px)",
							}
						}>
						<ButtonBack
							className={"tns-controls__arrows__prev"}
						>
							<Icon
								id={"dome-arrow"}
								className={"dome-arrow"}
							/>
							<Icon
								style={{ transform: "rotate(180deg)" }}
								id={"chevron"}
								className={"tns-controls__arrows__chevron tns-controls__arrows__prev__chevron"}
							/>
						</ButtonBack>
						<ButtonNext
							className="tns-controls__arrows__next"
						>
							<Icon
								id={"dome-arrow"}
								className={"dome-arrow"}
								style={{ transform: "rotate(180deg)" }}
							/>
							<Icon
								id={"chevron"}
								className={"tns-controls__arrows__chevron tns-controls__arrows__next__chevron"}
							/>
						</ButtonNext>
					</div>
				)
			}
		</CarouselProvider>
	);
}

WideImageCarousel.propTypes = {
	items: PropTypes.array,
	isClipboardEnable: PropTypes.bool,
	url: PropTypes.string,
	currentSlide: PropTypes.any,
};

WideImageCarousel.defaultProps = {
	items: [],
	isClipboardEnable: false,
};

WideImageCarousel.displayName = "WideImageCarousel";
