import React, { useEffect } from "react";
import get from "lodash/get";
import PropTypes from "prop-types";

import { TextBlockMedia, TextBlockImportantToKnow } from "../Blocks";
import { GalleryList } from "../Lists";
import { translit, markdownConverter } from "../../helpers";
import { NoIndex } from "../NoScript";
import { ContentButtons } from "../Buttons";
import WideImageCarousel from "../WideImageCarousel";
import VKEntrie from "../Blocks/VkPost";
import useUrl from "../../hooks/url";

export default function Contents({ items, date, button, articleBody }) {
	const url = useUrl();

	useEffect(() => {
		if (url?.hash) {
			const [dirtyHash] = url?.hash?.split("?");
			const hash = dirtyHash?.slice(1);

			if (hash) {
				setTimeout(() => {
					const elementToScrollTo = document.getElementById(hash);

					if (elementToScrollTo) {
						elementToScrollTo.scrollIntoView({
							block: "start",
							behavior: "smooth",
						});
					}
				}, 100);
			}
		}
	}, [url]);

	function makeContent(title, body, media, images, videoSrc, type, idx, button, style, url, currentSlide) {
		switch (get(media, "type", "default")) {
			case "Gallery":
				return (
					<TextBlockMedia
						title={title}
						desc={markdownConverter(body)}
						button={button}
						articleBody={articleBody}
						style={style}
					>
						<GalleryList items={images} orientation={type} />
					</TextBlockMedia>
				);
			case "WideImage":
				return (
					<TextBlockMedia
						title={title}
						desc={markdownConverter(body)}
						button={button}
						articleBody={articleBody}
						style={style}
					>
						<WideImageCarousel
							items={images}
							isClipboardEnable
							currentSlide={currentSlide}
							url={url}
						/>
					</TextBlockMedia>
				);
			case "VkArticle":
				return (
					<TextBlockMedia
						title={title}
						desc={markdownConverter(body)}
						button={button}
						articleBody={articleBody}
						style={style}
					>
						<VKEntrie
							article={media ? media.vk.article : {}}
						/>
					</TextBlockMedia>
				);
			case "ImportantToKnow":
				return (
					<TextBlockImportantToKnow
						title={title}
						style={style}
						desc={markdownConverter(body)}
					/>
				);
			case "VkPost":
				return (
					<TextBlockMedia
						postType={"vkPost"}
						title={title}
						style={style}
						postSrc={media ? media.vk.post : {}}
						desc={markdownConverter(body)}
						left={idx % 2 !== 0}
						orientation={type}
						videoSrc={videoSrc}
						files={media ? media.files : []}
						date={date}
						button={button}
						articleBody={articleBody}
					/>
				);
			case "InstaPost":
				return (
					<TextBlockMedia
						postType={"instaPost"}
						title={title}
						style={style}
						postSrc={get(media, "insta.src", "")}
						desc={markdownConverter(body)}
						left={idx % 2 !== 0}
						orientation={type}
						videoSrc={videoSrc}
						files={media ? media.files : []}
						date={date}
						button={button}
						articleBody={articleBody}
					/>
				);
			case "FbPost":
				return (
					<TextBlockMedia
						postType={"fbPost"}
						title={title}
						style={style}
						postSrc={get(media, "fb.src", "")}
						desc={markdownConverter(body)}
						left={idx % 2 !== 0}
						orientation={type}
						videoSrc={videoSrc}
						files={media ? media.files : []}
						date={date}
						button={button}
						articleBody={articleBody}
					/>
				);
			case "TwitterPost":
				return (
					<TextBlockMedia
						postType={"twitter"}
						title={title}
						style={style}
						postSrc={get(media, "twitter.src.tweetId", "")}
						desc={markdownConverter(body)}
						left={idx % 2 !== 0}
						orientation={type}
						videoSrc={videoSrc}
						files={media ? media.files : []}
						date={date}
						button={button}
						articleBody={articleBody}
					/>
				);
			default:
				return (
					<TextBlockMedia
						title={title}
						style={style}
						items={media ? media.items : []}
						desc={markdownConverter(body)}
						left={idx % 2 !== 0}
						orientation={type}
						videoSrc={videoSrc}
						files={media ? media.files : []}
						date={date}
						button={button}
						articleBody={articleBody}
					/>
				);
		}
	}

	return url?.url && items instanceof Array ? items.map(({ title, body, media, type, no_index, buttons = [], style = {} }, idx) => {
		const images = get(media, "items", [])
			.map(item => {
				return {
					id: item.id,
					src: get(item, "src", ""),
					alt: item.alt,
					title: item.title,
					w: 0,
					h: 0,
				};
			});
		let currentSlide = null;

		if (url?.hash) {
			const [dirtyHash, imgIndex] = url?.hash?.split("?");
			const hash = dirtyHash?.slice(1);
			const isHashBelongCurrentCarousel = images?.some(item => item.id === hash);
			currentSlide = isHashBelongCurrentCarousel && !isNaN(imgIndex) ? parseInt(imgIndex, 10) : currentSlide;
		}

		const videoSrc = get(media, "video_src", "");
		const contentBlock =
			<div key={`${translit(title)}-${idx}`}>
				{ !!buttons.length && <ContentButtons items={buttons} />}
				{makeContent(title, body, media, images, videoSrc, type, idx, button, style, url?.url, currentSlide)}
			</div>;

		return no_index ?
			<NoIndex>
				{contentBlock}
			</NoIndex>
			: contentBlock;
	})
		: null;
}

Contents.propTypes = {
	items: PropTypes.array,
	date: PropTypes.string,
	articleBody: PropTypes.string,
	button: PropTypes.object,
};

Contents.defaultProps = {
	items: [],
	date: "",
	articleBody: "",
	button: {},
};
