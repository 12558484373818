import { useState, useEffect } from "react";

export default function useUrl() {
	const [url, setUrl] = useState({});

	useEffect(() => {
		if (window !== undefined) {
			setUrl({
				url: `${location.origin}${location.pathname}`,
				hash: location.hash,
			});
		}
	}, []);

	return {
		url: url.url,
		hash: url.hash,
	};
}
